<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[75]">
        <div class="flex w-full flex-col items-end space-y-4">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <CheckCircleIcon v-if="notificationType==NotificationType.SUCCESS" class="h-6 w-6 text-green-400" aria-hidden="true" />
                                <ExclamationTriangleIcon v-if="notificationType==NotificationType.WARNING" class="h-6 w-6 text-amber-400" aria-hidden="true" />
                                <BugAntIcon v-if="notificationType==NotificationType.ERROR" class="h-6 w-6 text-red-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">{{title}}</p>
                                <p class="mt-1 text-sm text-gray-500">{{message}}</p>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button type="button" @click="show = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span class="sr-only">{{ ('Close') }}</span>
                                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>



<script setup lang="ts">
import {ref} from 'vue'
import {CheckCircleIcon, BugAntIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
import {XMarkIcon} from '@heroicons/vue/20/solid'
import {useGlobalEventLister, useNotificationEvent} from "~/composables/useEventBus";
import {NotificationType} from "~/utils/enums/NotificationType";


const show = ref<boolean>(false)
const title = ref<string>('')
const message = ref<string>('')
const notificationType = ref<NotificationType>();

function setupEventListener(){
    useGlobalEventLister(useNotificationEvent(), function (notification:any){
        show.value = true;
        title.value = notification.title;
        message.value = notification.message;
        notificationType.value = notification.type
        setTimeout(()=>{
            show.value = false;
        }, 4000);
    })
}

onMounted(()=>{
    setupEventListener();
})
</script>